<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12">
        <v-img :src="logo" class="my-3" contain height="200" />
      </v-col>

      <v-col class="mb-4">
        <h1 class="display-2 font-weight-bold mb-3">
          Welcome to Born Again Strength
        </h1>

        <p class="subheading font-weight-regular">
          "Train different, Be different."
        </p>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="4">
        <v-card
          hover
          class="mx-auto"
          max-width="400"
          :to="{ name: 'StrengthandConditioning' }"
        >
          <v-img class="white--text align-end" height="320px" :src="westonair">
          </v-img>
          <v-card-text class="text--primary text-center">
            <div class="mb-4 font-weight-bold">Strength and Conditioning</div>

            <div>Individualize Programs</div>
            <div>Semi-Private Training Model</div>
            <div>2-4 Training Sessions Per Week</div>

            <v-btn
              class="primary mt-3"
              :to="{ name: 'StrengthandConditioning' }"
              >More Infomation</v-btn
            >
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" md="4">
        <v-card
          hover
          class="mx-auto"
          max-width="400"
          :to="{ name: 'PDLanding' }"
        >
          <v-img
            class="white--text align-end"
            height="320px"
            :src="shelleypitching"
          >
          </v-img>
          <v-card-text class="text--primary text-center">
            <div class="mb-4 font-weight-bold">Player Development Programs</div>

            <div>5-Tool Prospect (Youth)</div>
            <div>Hitting Groups (Youth/HS)</div>
            <div>Complete Pitcher Program (HS)</div>

            <v-btn class="primary mt-3" :to="{ name: 'PDLanding' }"
              >More Infomation</v-btn
            >
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" md="4">
        <v-card
          hover
          class="mx-auto"
          max-width="400"
          :to="{ name: 'RentalInformation' }"
        >
          <v-img
            class="white--text align-end"
            height="320px"
            :src="gymoverview"
          >
          </v-img>
          <v-card-text class="text--primary text-center">
            <div class="mb-4 font-weight-bold">Facility Rentals</div>
            <div>Team Practice</div>
            <div>Individual Cage Rentals</div>
            <div>Special Events (Parties and Birthdays)</div>

            <v-btn class="primary mt-3" :to="{ name: 'RentalInformation' }"
              >More Infomation</v-btn
            >
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import logo from '@/assets/images/5Logo.svg'
import westonair from '@/assets/images/westonair.jpg'
import shelleypitching from '@/assets/images/shelleypitching.jpg'
import gymoverview from '@/assets/images/gymoverview.jpg'

export default {
  name: 'Landing',

  data() {
    return {
      logo: logo,
      westonair: westonair,
      shelleypitching: shelleypitching,
      gymoverview: gymoverview,
    }
  },
}
</script>

<style scoped>
a.link {
  text-decoration: none;
}
</style>
